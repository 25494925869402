<template>
  <div style="background:#ffffff;padding:15px">
    <div class="title">
      {{ detail.title }}
    </div>
    <div class="author">
      <span> {{ detail.author }} </span>
      <span style="color: #a6a6a6"> {{ detail.created_at }} </span>
    </div>
    <img class="banner" :src="detail.banner" alt="" />
    <div
      class="
        content
        cke_editable cke_editable_themed cke_contents_ltr cke_show_borders
      "
      v-html="detail.content"
    ></div>
  </div>
</template>

<script>
import { ArticleItem } from "@/api/article.js";
import "./contents.css";
export default {
  data() {
    return {
      detail: {},
      id: 0,
    };
  },

  created() {
    this.id = this.$route.params.id;
    this.ArticleItem();
  },

  methods: {
    //获取详情
    ArticleItem(id) {
      ArticleItem({
        id: this.id,
      }).then((res) => {
        if (res.errCode === 0) {
          this.detail = res.data;
          this.detail.created_at =
            this.detail.created_at.slice(5, 10).replace(/-/, "月") + "日";
          this.aaa();
        }
      });
    },

    aaa() {
      document.addEventListener("UniAppJSBridgeReady", function () {
        uni.postMessage({
          data: {
            action: "11111", // 这是传的参数
          },
        });
      });
    },
  },
};
</script>

<style lang="less">

.title {
  font-size: 21px;
  font-weight: bold;
  color: #272727;
  line-height: 30px;
}
.author {
  margin: 12px 0 33px;
  display: flex;
  align-items: center;
  span {
    font-size: 15px;
    color: #fb295b;
    margin-right: 11px;
  }
}
.banner {
  margin: 0 5px 20px;
  width: 335px;
  height: 77px;
}
.content {
  padding: 0 5px 20px;
  img {
    margin: 10px 0;
    width: 100%;
  }
}
</style>